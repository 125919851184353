<template>
  <div class="error-page error-page--500">
    <v-card class="text-center w-full pa-6" max-width="500px">
      <v-img src="../../assets/500.svg" max-height="400px" />
      <div class="display-2 mt-16">OOPS! Something went wrong here</div>
      <div class="mt-6 mb-10">Our experts are working to fix the issue.</div>
      <v-btn color="primary" @click="$router.go(-1)" link block>Send me Back</v-btn>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "InternalServer",

  layout: "error",

  metaInfo() {
    return { title: "500 Internal Server Error" };
  },
};
</script>
